<template>
  <div data-app>
    <v-data-table
      :headers="aHeaderProspectos"
      :items="aProspectos"
      :sort-desc="true"
      :search="sSearchProspectos"
      class="table-striped elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Listado de Prospectos</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="sSearchProspectos"
            append-icon="search"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          v-if="item.estadoID === 1"
          small
          class="mr-2 cursor-pointer"
          v-on:click="showProspecto(item)"
        >
          mdi-eye
        </v-icon>
      </template>
      <template v-slot:[`item.fechahora`]="{ item }">
        {{ formatFechaCreacion(item.fechahora) }}
      </template>
      <template v-slot:[`item.uniqueID`]="{ item }">
        {{ diasCreacion(item) }}
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialogShowProspecto"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card tile>
        <v-toolbar flat dark color="primary" style="flex: none">
          <v-btn icon dark @click="closeProspecto()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title
            >Prospecto:
            {{
              Object.keys(aProspecto).length > 0 &&
              aProspecto.nombreCompleto.toUpperCase()
            }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text class="pt-2 bg-default">
          <v-row class="font-size-120">
            <v-col cols="6">
              <div class="card card-custom">
                <div class="card-header">
                  <div class="card-title">
                    <h3 class="card-label">Datos Generales</h3>
                  </div>
                </div>
                <div class="card-body p-5 pb-7">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="aProspecto.email"
                        :readonly="true"
                        label="Email"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="aProspecto.rut"
                        :readonly="true"
                        label="Rut"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="aProspecto.nombres"
                        :readonly="true"
                        label="Nombre"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="aProspecto.apellido_paterno"
                        :readonly="true"
                        label="Apellido Paterno"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="aProspecto.apellido_materno"
                        :readonly="true"
                        label="Apellido Materno"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="aProspecto.celular"
                        :readonly="true"
                        type="number"
                        label="Teléfono"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-col>
            <v-col cols="6">
              <div class="card card-custom">
                <div class="card-header">
                  <div class="card-title">
                    <h3 class="card-label">Antecedentes</h3>
                  </div>
                </div>
                <div class="card-body p-5 pb-7">
                  <v-data-table
                    :headers="headersAntecedente"
                    :items="antecedentes"
                    :search="searchAntecendente"
                    item-key="key"
                    disable-pagination
                    hide-default-footer
                    class="table-striped elevation-1"
                    id="antecedente_table"
                  >
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-icon
                        small
                        class="mr-2 cursor-pointer"
                        v-on:click="descargarAntecedente(item)"
                      >
                        mdi-eye
                      </v-icon>
                    </template>
                  </v-data-table>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="text-right">
              <v-btn
                color="danger"
                dark
                class="mb-2"
                v-on:click="responderProspeccion('RECHAZAR')"
              >
                RECHAZAR
              </v-btn>
            </v-col>
            <v-col cols="6" class="text-left">
              <v-btn
                color="success"
                dark
                class="mb-2"
                v-on:click="responderProspeccion('APROBAR')"
              >
                APROBAR
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import {
  GET_PROSPECTOS_JOBBERS,
  GET_ANTECEDENTES_PROSPECTOS_JOBBERS,
  POST_ENVIO_RESPUESTA_PROSPECCION_JOBBER,
} from "@/core/services/store/jobber.module";
import { GET_REGIONES_PROVINCIAS_COMUNAS } from "@/core/services/store/global.module";
import Place from "../components/common/Places.vue";
import moment from "moment";
export default {
  name: "prospeccion_jobbers",
  components: {
    //eslint-disable-next-line
    Place
  },
  props: {},
  data() {
    return {
      aRegionesProvinciasComunas: [],
      aHeaderProspectos: [
        {
          text: "Rut",
          value: "rut",
        },
        {
          text: "Fecha Creación",
          value: "fechahora",
        },
        {
          text: "Días",
          value: "uniqueID",
        },
        {
          text: "Nombre",
          value: "nombreCompleto",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Teléfono",
          value: "celular",
        },
        {
          text: "Estado",
          value: "estado",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          fixed: true,
        },
      ],
      aProspectos: [],
      aProspecto: {},
      sSearchProspectos: "",
      dialogShowProspecto: false,
      dialogProspectoTitle: "",
      regiones: [],
      provincias: [],
      comunas: [],
      headersAntecedente: [
        {
          text: "Antecedente",
          value: "antecedente",
        },
        {
          text: "Puntaje",
          value: "puntaje",
          align: "center",
        },
        {
          text: "Fecha",
          value: "fecha",
        },
        {
          text: "",
          value: "actions",
        },
      ],
      antecedentes: [],
      searchAntecendente: "",
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "PROSPECCION DE JOBBERS", route: "prospeccion-jobbers" },
    ]);
  },
  created() {
    let oVue = this;
    oVue.getRegionesProvinciasComunas();
    oVue.getProspectos();
  },
  methods: {
    getProspectos() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.$store.dispatch(GET_PROSPECTOS_JOBBERS).then((aProspectos) => {
        oVue.aProspectos = aProspectos;
        KTUtil.removeLoading();
      });
    },
    getRegionesProvinciasComunas() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.$store
        .dispatch(GET_REGIONES_PROVINCIAS_COMUNAS)
        .then((aRegionesProvinciasComunas) => {
          oVue.aRegionesProvinciasComunas = aRegionesProvinciasComunas;
          KTUtil.removeLoading();
        });
    },
    showProspecto(aProspecto) {
      let oVue = this;
      oVue.dialogShowProspecto = true;
      oVue.aProspecto = aProspecto;
      oVue.getAntecedentesProspecto();
    },
    closeProspecto() {
      const oVue = this;
      oVue.dialogShowProspecto = false;
      oVue.aProspecto = {};
      oVue.getProspectos();
    },
    getAntecedentesProspecto() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.$store
        .dispatch(GET_ANTECEDENTES_PROSPECTOS_JOBBERS, this.aProspecto.uniqueID)
        .then((aAntecedentes) => {
          oVue.antecedentes = aAntecedentes;
          KTUtil.removeLoading();
        });
    },
    descargarAntecedente(aAntecedente) {
      window.open(aAntecedente.url, "_blank");
    },
    async responderProspeccion(sTipo) {
      const oVue = this;

      let bAprobar = null;

      switch (sTipo) {
        case "APROBAR":
          bAprobar = true;
          break;
        case "RECHAZAR":
          bAprobar = false;
          break;
      }

      const aData = {
        aprobar: bAprobar,
        prospecto: oVue.aProspecto.uniqueID,
      };

      oVue.enviarRespuestaProspeccion(aData);
    },
    async enviarRespuestaProspeccion(aData) {
      let oVue = this;

      const sMensaje = aData.aprobar
        ? "¿Desea Aprobar la solicitud?"
        : "¿Desea Rechazar la solicitud?";
      Swal.fire({
        title: "¡Alerta!",
        text: sMensaje,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "NO",
        confirmButtonText: "SI",
      }).then(async (response) => {
        if (response.isConfirmed) {
          KTUtil.setLoading();
          const aEnvioRespuestaResponse = await oVue.$store.dispatch(
            POST_ENVIO_RESPUESTA_PROSPECCION_JOBBER,
            aData
          );
          if (aEnvioRespuestaResponse.result) {
            Swal.fire({
              title: "¡Enhorabuena!",
              text: "La solicitud fue respondida correctamente.",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
            });
            oVue.closeProspecto();
          } else {
            Swal.fire({
              title: "Error al responder la tarea",
              text: "Comuniquese con el administrador del sistema.",
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
            });
          }
          KTUtil.removeLoading();
        }
      });
    },
    formatFechaCreacion(fechaCreacion) {
      return moment(fechaCreacion, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY");
    },
    diasCreacion(prospecto) {
      if (prospecto.estadoID !== 1) return;
      return moment().diff(
        moment(prospecto.fechahora, "YYYY-MM-DD HH:mm:ss"),
        "days"
      );
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
  },
  watch: {},
};
</script>
